import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"

var StyledDetails = styled.div`
  text-transform: uppercase;
  font-family: "IBM Plex Sans";
  display: flex;
  flex-flow: row wrap;
  font-size: 1.3rem;
  letter-spacing: 0.075em;
  span {
    margin-right: 30px;
  }
  margin-bottom: var(--space-medium);
`

const PostDetails = props => {
  console.log("POST DETAIL PROPS", props)
  return (
    <StyledDetails className="post__details">
      <span>{props.published}</span>
      <span>{props.project}</span>
    </StyledDetails>
  )
}

export default PostDetails
