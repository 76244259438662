import React from "react"

const { useState, useEffect } = React
export const ThemeContext = React.createContext({})

const ThemeProvider = props => {
  var [state, setState] = useState({ mode: "light" })
  console.log("THEME PROVIDER PROPS!", props)
  return (
    <ThemeContext.Provider value={[state, setState]}>
      {props !== undefined ? props.children : null}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
