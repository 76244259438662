import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const StyledCode = styled.div`
  background-color: #14121a;
  /* color: #0a090d; */
  /* 100 is starting width. 30 is the margin left and right. 60px is total padding left and right.  */
  width: calc(100% - 30% - 60px);
  margin-left: 25%;
  padding: 30px 30px 15px 30px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1.7rem;
  line-height: 1.75em;
  margin-bottom: var(--space-medium);
  overflow: initial;
  .prism-code {
    overflow: auto;
    /* https://css-tricks.com/almanac/properties/w/whitespace/ */
    /* handles how white space appears, allow scrolling on desktop */
    /* white-space: pre-wrap !important;
    word-wrap: break-word !important; */
  }

  ${mq[1]} {
    /* 100% is the max space in this container and 60 represents the padding */
    width: calc(100% - 60px);
    margin-left: 0;
  }
`

const PostCode = props => {
  return <StyledCode className="post__codeblock">{props.children}</StyledCode>
}

export default PostCode
