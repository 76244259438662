import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import BgImage from "../../images/00_bgPhoto_design.jpeg"

var StyledHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  z-index: 10;
  color: black;
  margin-top: 120px;
  line-height: 1.5em;
  /* align-items: baseline;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; */
  width: 960px;
  font-size: 2rem;
  font-family: "IBM Plex Sans";
  h3 {
    width: 25%;
    font-weight:500;
    ${mq[1]} {
      width: 100%;
      margin-bottom: var(--space-xsmall);
    }
  }
  p {
    width: 75%;
    margin-bottom: var(--space-large);
    ${mq[1]} {
      width: 100%;
    }
  }
`

const WelcomeHeader = () => {
  return (
    <div className="site__container">
      <StyledHeader className="welcome__header">
        <h3>About this site:</h3>
        <p>
          Welcome to Jeremy Builds, a site openly sharing and documenting both the
          design and code process of{" "}
          <a
            href="https://twitter.com/JeremyStuBarnes"
            target="_blank"
            className="link__underline"
          >
            Jeremy Barnes
          </a>{" "}
          through invididual projects.
        </p>
      </StyledHeader>
    </div>
  )
}

export default WelcomeHeader
