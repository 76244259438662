import React from "react"
import styled from "@emotion/styled"
import mq from "./Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "./constants/routes.js"

var StyledDiv = styled.div`
  width: 960px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  padding-top: 60px;
  padding-bottom: 60px;
  .newsletter__copy {
    width: 50%;
    margin-right: 10%;
    ${mq[2]} {
      width: 100%;
      margin-right: 0;
    }
    h1 {
      font-family: "Tiempos Headline Semibold";
      font-size: 3.4rem;
      margin-bottom: 20px;
    }
    p {
      font-family: "IBM Plex Sans";
      font-size: 1.8rem;
      line-height: 1.6em;
    }
  }
  form {
    width: 40%;
    display: flex;
    flex-flow: row wrap;
    margin-top: 68px;
    ${mq[2]} {
      width: 100%;
      margin-top: 30px;
    }
    .form__text {
      width: 255px;
      ${mq[1]} {
        width: 100%;
        margin-bottom: 15px;
      }
      input[type="text"] {
        -webkit-appearance: none !important;
        width: 100%;
        height: 44px;
        font-family: "IBM Plex Sans";
        font-size: 1.6rem;
        padding-left: 13px;
        box-sizing: border-box;
      }
    }
    .form__submit {
      width: 115px;
      margin-left: auto;
      ${mq[2]} {
        margin-left: 15px;
      }
      ${mq[1]} {
        width: 100%;
        margin-left: 0px;
      }
      input[type="submit"] {
        -webkit-appearance: none !important;
        width: 100%;
        height: 44px;
        color: white;
        font-size: 1.6rem;
        font-family: "IBM Plex Sans";
        cursor: pointer;
        background-color: var(--light--accent-primary);
        border-radius: 4px;
        border: none;
        transition: 0.25s ease-out;
        :hover {
          background-color: #5826bf;
        }
      }
    }
  }
`

const NewsletterForm = () => {
  return (
    <div className="site__container">
      <StyledDiv className="newsletter__container">
        <div className="newsletter__copy">
          <h1>Follow the Process</h1>
          <p>
            Like what you’re reading? I send out a{" "}
            <a
              href="https://tinyletter.com"
              target="_blank"
              className="link__underline"
            >
              newsletter
            </a>{" "}
            about my most recent posts, so you can follow along.
          </p>
        </div>
        <form
          className="newsletter__form"
          action="https://tinyletter.com/jeremyb1988"
          method="post"
          target="popupwindow"
          onsubmit="window.open('https://tinyletter.com/jeremyb1988', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
        >
          <div className="form__text">
            <input
              type="text"
              name="email"
              id="tlemail"
              placeholder="Your Email"
            />
          </div>
          <div className="form__submit">
            <input type="hidden" value="1" name="embed" />
            <input type="submit" value="Join Now" />
          </div>
        </form>
        {/* <p>
          <a href="https://tinyletter.com" target="_blank">
            powered by TinyLetter
          </a>
        </p> */}
        {/* <p>Note: I will never share your email nor spam you with nonsense.</p> */}
      </StyledDiv>
    </div>
  )
}

export default NewsletterForm
