import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "../constants/routes.js"

var StyledTitle = styled.h1`
  font-size: 4.8rem;
  margin-bottom: var(--space-xsmall);
  ${mq[0]} {
    font-size: 3.4rem;
  }
`

const PostTitle = props => {
  return <StyledTitle className="post__title">{props.title}</StyledTitle>
}

export default PostTitle
