import React from "react"
import styled from "@emotion/styled"
import mq from "./Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "./constants/routes.js"
import ThemeProvider from "./ThemeProvider.js"

var StyledFooter = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 960px;
  margin: auto;
  padding-top: var(--space-medium);
  margin-bottom: var(--space-xlarge);
  font-size: 1.6rem;
  align-items: baseline;
  font-family: "IBM Plex Sans", helvetica;
  p {
    width: 30%;
    line-height: 1.6em;
    margin-bottom: var(--space-small);
    ${mq[1]} {
      width: 100%;
    }
  }
  span {
    margin-left: auto;
    ${mq[1]} {
      margin-left: 0;
    }
  }
`

const Footer = () => {
  return (
    <div className="site__container">
      <StyledFooter className="site__footer">
        <p>
          This site was proudly built with{" "}
          <a className="link__underline" href="https://www.gatsbyjs.org/">
            Gatsby
          </a>{" "}
          and deployed with{" "}
          <a className="link__underline" href="https://www.netlify.com/">
            Netlify
          </a>
          .
        </p>
        <span>© Jeremy Barnes 2019</span>
      </StyledFooter>
    </div>
  )
}

export default Footer
