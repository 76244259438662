import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

var getMetaData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        siteImage: image
        siteUrl
        twitterUserName
        author
      }
    }
  }
`

const SEO = props => {
  var {
    site: { siteMetadata },
  } = useStaticQuery(getMetaData)
  var {
    siteTitle,
    siteDescription,
    siteImage,
    siteUrl,
    twitterUserName,
    author,
  } = siteMetadata
  return (
    <Helmet title={`${siteTitle} | ${props.title}`}>
      <meta name="description" content={`${siteDescription}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:creator" content={twitterUserName} />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:image" content={`${siteUrl}${siteImage}`} />
    </Helmet>
  )
}

export default SEO
