import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Highlight, { defaultProps } from "prism-react-renderer"
import nightOwl from "prism-react-renderer/themes/nightOwl/index.js"
import PostCode from "./post-code.js"

// also oceanic next is nice

const MyH1 = props => <h1 style={{ color: "tomato" }} {...props} />
const MyParagraph = props => (
  <p
    style={{
      fontSize: "18px",
      lineHeight: 1.6,
      backgroundColor: "blue !important",
      color: "white",
    }}
  />
)

//  all supported languages
// https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js
// https://mdxjs.com/guides/syntax-highlighting
const MyCode = props => {
  const language = props.children.props.className.replace(/language-/, "")
  return (
    <PostCode>
      <Highlight
        {...defaultProps}
        theme={nightOwl}
        code={props.children.props.children}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </PostCode>
  )
}

//https://www.gatsbyjs.org/docs/mdx/customizing-components/
const components = {
  pre: MyCode,
}

export default ({ children }) => {
  //  here I use children because I need to supply this provider to MDX renderer.
  return <MDXProvider components={components}>{children}</MDXProvider>
}
