import React from "react"
import styled from "@emotion/styled"
import mq from "./Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import Navbar from "./NavBar.js"
import Footer from "./Footer.js"
import "./Layout.css"
import { ThemeContext } from "../components/ThemeProvider.js"
import { ThemeProvider } from "emotion-theming"

var darkTheme = {
  colors: {
    backgroundPrimary: "var(--dark--background-primary)",
    textPrimary: "var(--dark--text-primary)",
    textAccent: "var(--dark--text-secondary)",
    textSecondary: "var(--dark--text-secondary)",
    borderColor: "1px solid rgba(255, 255, 255, 0.25)",
    linkColor: "var(--dark--text-link)",
    inputField: "var(--dark-input-field)",
    inputBorder: "1px solid rgba(255, 255, 255, 0.1)",
    inputTextColor: "rgba(255, 255, 255, 0.35)",
  },
  typography: {
    titleFont: "IBM Plex Mono",
    titleWeight: 600,
    welcomeWeight: 300,
    welcomeLetterSpacing: ".028em",
    bodyFont: "IBM Plex Sans",
    bodySize: "2rem",
    bodyWeight: 300,
    bodyLetterSpacing: ".028em",
    bodyLineHeight: "1.7em",
    bodyHeaderFont: "IBM Plex Mono",
    detailWeight: 500,
    codeKeywordSize: "1.9rem",
  },
  mobile: {
    bodySize: "1.8rem",
    codeKeywordSize: "1.8rem",
  },
}

var lightTheme = {
  colors: {
    backgroundPrimary: "var(--light-background-primary)",
    textPrimary: "var(--light-text-primary)",
    textAccent: "var( --light--accent-primary)",
    textSecondary: "var( --light-text-secondary)",
    borderColor: "1px solid rgba(0, 0, 0, 0.25)",
    linkColor: `var(--light--accent-primary)`,
    inputField: "white",
    inputBorder: "1px solid rgba(0, 0, 0, 0.15)",
    inputTextColor: "rgba(0, 0, 0, 0.35)",
  },
  typography: {
    titleFont: "Tiempos Headline Semibold",
    titleWeight: "normal",
    welcomeWeight: 400,
    welcomeLetterSpacing: "none",
    bodyFont: "Tiempos Text Regular",
    bodySize: "2rem",
    bodyWeight: "normal",
    bodyLetterSpacing: "0",
    bodyLineHeight: "1.6em",
    bodyHeaderFont: "IBM Plex Sans",
    detailWeight: 500,
    codeKeywordSize: "1.9rem",
  },
  mobile: {
    bodySize: "1.7rem",
    codeKeywordSize: "1.6rem",
  },
}

var { useContext } = React

var StyledLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  .newsletter__container {
    border-top: ${props => props.theme.colors.borderColor};
    .newsletter__copy {
      letter-spacing: ${props => props.theme.typography.bodyLetterSpacing};
      p {
        color: ${props => props.theme.colors.textSecondary};
      }
    }
    .form__text {
      input[type="text"] {
        background-color: ${props => props.theme.colors.inputField};
        color: ${props => props.theme.colors.textPrimary};
        border: ${props => props.theme.colors.inputBorder};
        letter-spacing: ${props => props.theme.typography.bodyLetterSpacing};
        ::placeholder {
          color: ${props => props.theme.colors.inputTextColor};
        }
      }
    }
  }
  .site__container {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    ${mq[2]} {
      max-width: 90%;
    }
  }
  .welcome__header {
    color: ${props => props.theme.colors.textPrimary};
    border-bottom: ${props => props.theme.colors.borderColor};
    letter-spacing: ${props => props.theme.typography.welcomeLetterSpacing};
    font-weight: ${props => props.theme.typography.welcomeWeight};
    p {
      color: ${props => props.theme.colors.textSecondary};
    }
  }
  .site__footer {
    color: ${props => props.theme.colors.textPrimary};
    border-top: ${props => props.theme.colors.borderColor};
  }
  .post__title {
    font-family: ${props => props.theme.typography.titleFont};
    font-weight: ${props => props.theme.typography.titleWeight};
    line-height: 1.15em;
  }
  .post__body {
    font-family: ${props => props.theme.typography.bodyFont};
    font-weight: ${props => props.theme.typography.bodyWeight};
    font-size: ${props => props.theme.typography.bodySize};
    letter-spacing: ${props => props.theme.typography.bodyLetterSpacing};
    margin-bottom: var(--space-medium);
    line-height: ${props => props.theme.typography.bodyLineHeight};
    ${mq[0]} {
      font-size: ${props => props.theme.mobile.bodySize};
      line-height: 1.6em;
    }
    h1 {
      font-size: 2.6rem;
      font-family: ${props => props.theme.typography.bodyHeaderFont};
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: var(--space-xsmall);
    }
    span {
      font-family: "IBM Plex Mono", monospace;
      font-size: ${props => props.theme.typography.codeKeywordSize};
      background-color: #0a090d;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 6px;
      padding: 2px 6px;
      color: rgb(127, 219, 202);
      /* color: rgba(255, 255, 255, 1); */
      ${mq[0]} {
        font-size: ${props => props.theme.mobile.codeKeywordSize};
      }
    }
  }
  .post__caption {
    color: ${props => props.theme.colors.textAccent};
    letter-spacing: ${props => props.theme.typography.bodyLetterSpacing};
  }
  .post__caption--image {
    color: ${props => props.theme.colors.textSecondary};
    letter-spacing: ${props => props.theme.typography.bodySpacing};
    font-family: "IBM Plex Sans";
    font-size: 1.4rem;
    margin-top: var(--space-xsmall);
  }
  .post__details {
    color: ${props => props.theme.colors.textSecondary};
    font-weight: ${props => props.theme.typography.detailWeight};
  }
  .post__lastElement {
    margin-bottom: 0;
  }
  .prism-code {
    background-color: #14121a !important;
    font-family: "IBM Plex Mono", Courier, monospace;
  }
  [class*="col"] {
    margin-left: 4%;
  }
  .col-1 {
    width: 12%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .col-2 {
    width: 28%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .col-3 {
    width: 44%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .col-4 {
    width: 60%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .col-5 {
    width: 76%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .col-6 {
    width: 92%;
    ${mq[0]} {
      width: 92%;
    }
  }
  .link__underline {
    /* must position the parent as relative */
    position: relative;
    color: ${props => props.theme.colors.linkColor};
    cursor: pointer;
    ::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
      background-color: ${props => props.theme.colors.linkColor};
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s;
    }
    :hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
  /* .link__underline {
    a {
      :before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -5px;
        left: 0;
        background-color: white;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
      :hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  } */
  .link__active {
    background-color: var(--light--accent-primary);
  }
`

var { useEffect } = React

const Layout = props => {
  // get the current state I have out of the Theme Context
  var [state, setState] = useContext(ThemeContext)
  // set the mode to whatever theme I have set up in the index.js or post-list-template component
  useEffect(() => {
    setState(() => (state = { mode: props.theme }))
  }, [])
  return (
    // get the ThemeProvider component from emotion and tell it to use a theme
    // based off of what state.mode equals ( state.mode is from my ThemeContext)
    <ThemeProvider theme={state.mode == "dark" ? darkTheme : lightTheme}>
      <StyledLayout>
        <Navbar />
        {props.children}
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  )
}

export default Layout
