import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "../constants/routes.js"
import PostTitle from "./post-title.js"
import PostBlock from "./post-block.js"
import PostBody from "./post-body.js"
import PostCaption from "./post-caption.js"
import PostDetails from "./post-details.js"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ThemeProvider from "../ThemeProvider.js"
import Image from "gatsby-image"

import MDXTheme from "./MDXTheme.js"

var StyledPost = styled.div`
  align-items: baseline;
  margin-bottom: var(--space-large);
  .post__titleSection {
    width: 70%;
    ${mq[1]} {
      width: 100%;
    }
  }
  .post__empty {
    width: 20%;
    margin-right: 5%;
    display: block;
    ${mq[1]} {
      width: 0;
      margin-right: 0;
    }
  }
  .post__image {
    margin-bottom: var(--space-medium);
  }
`

const SinglePost = props => {
  var { node } = props.post

  return (
    <StyledPost className="site__container">
      {/* <PostBlock>
        <div className="post__empty"></div>
        <div className="post__titleSection post__image">
          <Image fluid={node.frontmatter.coverImage.childImageSharp.fluid} />
        </div>
      </PostBlock> */}
      <PostBlock>
        <div className="post__empty"></div>
        <div className="post__titleSection">
          <PostTitle title={node.frontmatter.title} />
          <PostDetails
            published={node.frontmatter.published}
            project={node.frontmatter.project}
          />
        </div>
      </PostBlock>
      <MDXTheme>
        <MDXRenderer>{node.body}</MDXRenderer>
      </MDXTheme>
    </StyledPost>
  )
}

export default SinglePost
