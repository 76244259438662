import SinglePost from "./single-post.js"
import React from "react"
import styled from "@emotion/styled"
import mq from "../Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "../constants/routes.js"

var StyledList = styled.div`
  margin-top: var(--space-xlarge);
  ${mq[1]} {
    margin-top: var(--space-large);
  }
`

const PostList = props => {
  return (
    <StyledList>
      {props.posts.map(post => {
        return <SinglePost post={post} />
      })}
    </StyledList>
  )
}

export default PostList
