import React from "react"
import styled from "@emotion/styled"
import mq from "./Utilities/MediaQuery.js"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Routes from "./constants/routes.js"
import ThemeProvider from "./ThemeProvider.js"

var StyledNav = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  background-color: #231e2b;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  z-index: 50;
  /* -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased; */
  a {
    width: 50%;
    height: 50px;
    color: white;
    font-size: 2.1rem;
    text-align: center;
    vertical-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    text-transform: capitalize;
    transition: 0.25s ease-in-out;
    letter-spacing: 0.025em;
    :hover {
      background-color: var(--light--accent-primary);
      transition: 0.25s ease-in-out;
    }
    .link__code {
      font-family: "IBM Plex Mono";
    }
    .link__design {
      font-family: "Tiempos Text Regular" Georgia;
    }
    ${mq[0]} {
      font-size: 1.8rem;
      .link__code {
        font-size: 1.9rem;
      }
    }
  }
`

const NavBar = () => {
  return (
    <StyledNav>
      {Routes.map((route, index) => {
        if (index == 0) {
          return (
            <Link to={route.path} activeClassName="link__active">
              <h3 className="link__design">{route.name}</h3>
            </Link>
          )
        }
        if (index == 1) {
          return (
            <Link to={route.path} activeClassName="link__active">
              <h3 className="link__code">{route.name}</h3>
            </Link>
          )
        }
      })}
    </StyledNav>
  )
}

export default NavBar
